import { DisplayViewData } from '@/components/BlockArrayWrapper/TextBlockWithImage/DisplayViews/displayViews.model'
import USBButton from '@usb-shield/react-button'
import { USBColumn, USBGrid } from '@usb-shield/react-grid'
import USBLink from '@usb-shield/react-link'
import {
  defaultColLayoutOptions,
  defaultGridOptions,
} from '@/utils/usb-grid.util'
import styles from '@/components/BlockArrayWrapper/TextBlockWithImage/DisplayViews/styles/twoColWithImage.module.scss'

let gridSettings = defaultGridOptions

const contentBlock: Object = {
  ...defaultColLayoutOptions,
  spans: {
    xlarge: 6,
    large: 6,
    medium: 8,
    small: 4,
  },
  justify: 'stretch',
  padding: 'zero',
}

const contentBlockforFourColImage: Object = {
  ...defaultColLayoutOptions,
  spans: {
    xlarge: 4,
    large: 4,
    medium: 8,
    small: 4,
  },
  justify: 'stretch',
}

const contentBlockforFourColImageText: Object = {
  ...defaultColLayoutOptions,
  spans: {
    xlarge: 8,
    large: 8,
    medium: 8,
    small: 4,
  },
  justify: 'stretch',
}

const imageBlock: Object = {
  ...defaultColLayoutOptions,
  spans: {
    xlarge: 4,
    large: 4,
    medium: 8,
    small: 4,
  },
  justify: 'stretch',
  padding: 'zero',
}

const TwoColWithImage = ({
  title,
  shortDescription,
  ctaText,
  ctaLink,
  ctaLink2,
  ctaText2,
  image,
  displayView,
  linkAsButton,
  levelDownHeads,
  fourUp,
}: DisplayViewData) => {
  const isElavon = process.env.SITE_BRAND === 'elavon'
  const featureCountryCode: boolean = process.env.FEATURE_COUNTRY_CODE !== 'false'
  let renderSection: any = '',
    imageColClass: any = '',
    textColClass: any = '',
    imageDim: any = '',
    textDim: any = ''
  let usbButton = (
      <USBButton
        ctaStyle="standard"
        emphasis="heavy"
        size="medium"
        handleClick={() => {
          window.open(ctaLink, '_self')
        }}
        addClasses={styles.ctaButton}
      >
        {ctaText}
      </USBButton>
    ),
    usbLink = (
      <USBLink 
        linkType="arrow" 
        href={ctaLink} 
        addClasses={isElavon ? styles.elavonLightBlueCtaLink : featureCountryCode ? styles.lightBlueCtaLink: styles.ctaLink}
      >
        {ctaText}
      </USBLink>
    )
  
  let link: any = usbLink
  
  if (ctaLink && ctaLink.length > 0) {
    if (linkAsButton === 'true') {
      link = usbButton
    }
  }

  switch (displayView) {
    case 'TwoColImageLeft':
      textColClass = styles.textDisplayImageLeftLayout
      imageColClass = styles.imageDisplayImageLeftLayout
      imageDim = contentBlock
      textDim = contentBlock
      renderSection = GetImageColumn()

      break
    case 'TwoColImageLeftSmall':
      textColClass = styles.textDisplayImageLeftLayout
      imageColClass = styles.imageDisplayImageLeftLayout
      imageDim = contentBlockforFourColImage
      textDim = contentBlockforFourColImageText
      renderSection = GetImageColumn()

      break
    case 'TwoColImageRight':
      textColClass = styles.textDisplayImageRightLayout
      imageColClass = styles.imageDisplayImageRightLayout
      imageDim = contentBlock
      textDim = contentBlock
      renderSection = GetImageColumn()

      break

    case 'TwoColSmallImageLeft':
      textColClass = styles.textDisplayImageLeftLayout
      imageColClass = styles.imageDisplayImageLeftLayout
      imageDim = imageBlock
      textDim = contentBlock
      renderSection = GetImageColumn()

      break
  }

  function GetImageColumn() {
    return (
      <>
        <USBGrid
          addClasses={
            featureCountryCode
              ? styles.gridStyles + ' ' + styles.extendedMarginLayout
              : styles.gridStyles + ' ' + styles.marginLayout
          }
          gridGap={gridSettings.gridGap}
          alignItems={gridSettings.alignItems}
          columnCount="12"
          justifyContent={gridSettings.justifyContent}
          display={gridSettings.display}
          padding={'zero'}
          type={gridSettings.type}
        >
          <USBColumn
            layoutOpts={imageDim}
            addClasses={
              featureCountryCode
                ? imageColClass + ' ' + styles.columnFullWidth
                : imageColClass + ' ' + styles.fullWidth
            }
          >
            <div className={styles.imageWrapper}>
              <img src={image} alt="" />
            </div>
          </USBColumn>
          <USBColumn
            layoutOpts={textDim}
            addClasses={textColClass + ' ' + styles.fullWidth}
          >
            {levelDownHeads ? (
              <h4 className={styles.headH4}>{title}</h4>
            ) : (
              <h3
                className={
                  featureCountryCode ? styles.lightHeadH3 : styles.headH3
                }
              >
                {title}
              </h3>
            )}
            <span
              className={
                featureCountryCode ? styles.lightParagraph : styles.paragraph
              }
              dangerouslySetInnerHTML={{ __html: shortDescription }}
            />
            {link}
            {ctaText2 && (
              <USBLink linkType="arrow" href={ctaLink2} addClasses={isElavon ? styles.elavonLightBlueCtaLink : styles.lightBlueCtaLink}>
                {ctaText2}
              </USBLink>
            )}
          </USBColumn>
        </USBGrid>
      </>
    )
  }

  return <>{renderSection}</>
}

export default TwoColWithImage
